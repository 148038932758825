import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//强制下线
export const userLogout = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/logout",
  });

//用户冻结
export const userFreeze = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/freeze",
  });

//获取用户列表
export const getUserManagerList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/manager/list",
  });

//导出用户列表
export const exportUserManagerList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/manager/list`,
  });

//修改类型
export const userManagerIdentity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/manager/identity",
  });

//获取用户记录
export const getUserRecordList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/record/list",
  });

//导出用户记录
export const exportUserRecordList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/record/export`,
  });

//获取聊天历史
export const getChatHistory = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/chat/history",
  });

//获取聊天列表
export const getChatList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/chat/list",
  });

//导出聊天数据
export const exportChatList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/chat/list`,
  });

//获取警报类型
export const getRiskList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/risk/im/label/list",
  });

//聊天列表统计详情
export const getChatTotalinfo = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/chat/totalinfo",
  });

//聊天列表
export const getChatRecordList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/chat/record/list",
  });

//获取签到日志列表
export const getSignLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/sign/log/list",
  });

//签到奖励配置列表
export const getSignAwardSetList = () =>
  request({
    method: "post",
    url: "/adm/sign/award/set/list",
  });

//添加/修改签到奖励配置
export const saveSignAwardSet = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/sign/award/set/save",
  });

//获取礼包列表
export const getGiftAllList = () =>
  request({
    method: "post",
    url: "/adm/shop/gift/all",
  });

//获取用户行为上报列表
export const getUserActionReportList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user_action_report/list",
  });

//导出用户行为上报列表
export const exportUserActionReportList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user_action_report/export`,
  });

//风险用户列表
export const getUserRiskList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/risk/list",
  });

//添加风险用户
export const saveUserRisk = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/risk/save",
  });

//删除风险用户
export const removeUserRisk = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/risk/remove",
  });

//审核风险用户
export const auditUserRisk = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/risk/audit",
  });

//导出风险用户列表
export const exportUserRiskList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/user/risk/export`,
  });

//冻结记录
export const lockList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/lock/list",
  });

//修改城市
export const managerCity = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/manager/city",
  });
